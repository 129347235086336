import React from "react";
import BaseLayout from "../../components/layouts/baseLayout";
import TextJumbotron from "../../components/util/textJumbotron";
import { Container, Row, Col, CardDeck } from "react-bootstrap";
import ClassCard from "../../components/home/classCard";

export default function HighschoolPage({ data }) {
  return (
    <BaseLayout pageTitle="Tutoring: Highschool">
      <TextJumbotron heading="Tutoring: Highschool" />
      <Container>
        <Row>
          <Col>
            <ul>
              <li>
                Starting with a free consultation, we make sure our learning
                environment is the right fit for your child
              </li>
              <li>We only work with certified teachers or qualified mentors</li>
              <li>
                Our teachers provide support for quizzes, assignments, general
                concept review, test preparation, and final exam preparation
              </li>
              <li>
                Each student is taught in an environment with a low student to
                teacher ratio.
              </li>
              <li>
                Perfect if your child needs to get grades, test scores and
                confidence up.
              </li>
              <li>
                Convenient scheduling and affordable hourly rates with flexible
                payment options
              </li>
              <li>
                Tutors are able to provide support in study skills such as
                organizational strategies, test preparation to reduce anxiety,
                note taking, memorization strategies and more!
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="pb-3">
            <h2 className="text-center">Subjects Offered</h2>
          </Col>
          <Col xs={12}>
            <CardDeck>
              <ClassCard
                btnText="Register"
                btnLink="/registration"
                title="Math"
              >
                <p>
                  Our math tutoring programs make math easier to understand with
                  engaging, fun math lessons that help students gain a better
                  understanding of the material.
                </p>
              </ClassCard>
              <ClassCard
                btnText="Register"
                btnLink="/registration"
                title="English"
              >
                <p>
                  English programs are designed to help students build more
                  effective reading, writing, grammar and comprehension skills
                  that will help them achieve better English grades.
                </p>
              </ClassCard>
              <ClassCard
                btnText="Register"
                btnLink="/registration"
                title="Physics"
              >
                <p className="bg-warning">
                  {" "}
                  <strong>TODO</strong>
                </p>
              </ClassCard>
            </CardDeck>
          </Col>
          <Col xs={12} className="pt-3">
            <CardDeck>
              <ClassCard
                btnText="Register"
                btnLink="/registration"
                title="Chemistry"
              >
                <p className="bg-warning">
                  {" "}
                  <strong>TODO</strong>
                </p>
              </ClassCard>
              <ClassCard
                btnText="Register"
                btnLink="/registration"
                title="Calculus"
              >
                <p className="bg-warning">
                  {" "}
                  <strong>TODO</strong>
                </p>
              </ClassCard>
            </CardDeck>
          </Col>
        </Row>
      </Container>
    </BaseLayout>
  );
}
